import React from "react";
import classnames from "classnames";
import { Tag } from "../export";

const Switcher = ({
	options = ["Switcher", "Switcher"],
	activeIndex,
	type = "gray",
	size = "small",
	className = "",
	onClick = () => {},
}) => {
	return (
		<div className={classnames(`switcher  bg-${type}`, className)}>
			{options.map((item, i) => {
				return (
					<Tag
						tag={item.title}
						type={activeIndex === i ? "black" : "gray"}
						size={size}
						item={item}
						key={i}
						onClick={() => onClick(i)}
						activeTag={activeIndex === i}
					/>
				);
			})}
		</div>
	);
};

export default Switcher;
