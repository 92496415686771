import React, { useEffect, useRef, useContext } from "react";
import { Header, Footer } from "../export";
import classnames from "classnames";

const LayoutApp = ({ children, togglLocale }) => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<>
			<Header togglLocale={togglLocale} />

			{/* <div style={{ width: "100%", position: "relative" }}> */}

			<main className='app-content'>
				{children}
				<Footer />
			</main>
		</>
	);
};

export default LayoutApp;
