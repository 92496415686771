export const LogoFullColor = () => (
	<svg
		width='120'
		height='72'
		viewBox='0 0 120 72'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M59.9998 3.91797C58.6881 3.91797 57.4174 4.042 56.1878 4.24871C55.204 4.41408 54.2203 4.66214 53.2775 4.99288C46.9242 7.1427 41.9235 12.1865 39.792 18.5946C39.4641 19.5455 39.2182 20.5378 39.0542 21.53L41.3496 23.8452L43.3171 25.8296L51.474 34.0568C53.3595 35.9173 55.7779 37.1575 58.5242 37.4883C59.016 37.5296 59.4669 37.571 59.9588 37.571C65.9022 37.571 70.903 33.1886 71.8867 27.4833L69.4683 25.0441C69.4683 25.1268 69.4683 25.2508 69.4683 25.3335C69.4683 30.6254 65.2054 34.925 59.9588 34.925C57.4174 34.925 55.122 33.9328 53.4005 32.2791L47.9079 26.7392L41.8415 20.6204C43.522 13.9643 48.7277 8.75507 55.327 7.01867C56.3517 6.77062 57.3765 6.5639 58.4832 6.48122C58.975 6.43987 59.4669 6.43988 59.9998 6.43988C69.5503 6.43988 77.4613 13.7162 78.568 23.0597L81.2733 25.7883C81.2733 25.6229 81.2733 25.4575 81.2733 25.2922C81.2733 13.5508 71.7227 3.91797 59.9998 3.91797Z'
			fill='#0085FF'
		/>
		<path
			d='M78.6502 26.9045L76.7647 25.0027L68.5668 16.7755C68.6898 16.8996 68.7718 17.0236 68.8947 17.1063C67.0092 14.9978 64.3859 13.5921 61.4347 13.2614C60.9428 13.2201 60.4919 13.1787 60 13.1787C54.0566 13.1787 49.0559 17.561 48.0721 23.2663L50.4905 25.7056C50.4905 25.6229 50.4905 25.4989 50.4905 25.4162C50.4905 20.1243 54.7534 15.8246 60 15.8246C62.5414 15.8246 64.8368 16.8169 66.5583 18.4706C66.5993 18.5119 66.5993 18.5119 66.6403 18.5533C66.6813 18.5946 66.6813 18.5946 66.7223 18.636L72.0099 23.9692L73.9774 25.9536L78.1173 30.1292C76.4368 36.7854 71.2311 42.0359 64.6318 43.731C63.6071 43.9791 62.5824 44.1858 61.4757 44.2685C60.9838 44.3098 60.4919 44.3098 59.959 44.3098C50.4085 44.3098 42.4976 37.0335 41.3908 27.69L38.6855 24.9614C38.6855 25.1268 38.6855 25.2921 38.6855 25.4575C38.6855 37.2815 48.2361 46.9144 59.959 46.9144C61.2707 46.9144 62.5414 46.7904 63.7711 46.5837C64.7548 46.4183 65.7386 46.1702 66.6813 45.8395C73.0347 43.6897 78.0354 38.6458 80.1668 32.2377C80.4947 31.2868 80.7407 30.2946 80.9046 29.3024L78.6502 26.9045Z'
			fill='#0085FF'
		/>
		<path
			d='M10.5259 66.635C7.32869 66.635 4.86933 64.1544 4.86933 60.5989C4.86933 57.0434 7.36968 54.6042 10.5259 54.6042C12.1654 54.6042 13.6411 55.3897 14.5018 56.5887L15.4856 55.9685C14.3379 54.5215 12.6983 53.5293 10.5259 53.5293C6.67286 53.5293 3.63965 56.4233 3.63965 60.5989C3.63965 64.7745 6.67286 67.6685 10.5259 67.6685C12.6983 67.6685 14.2559 66.7176 15.4856 65.2293L14.5018 64.6092C13.6411 65.8081 12.1245 66.635 10.5259 66.635Z'
			fill='#5E6768'
		/>
		<path
			d='M21.9208 57.2915C19.0516 57.2915 17.207 59.6067 17.207 62.5007C17.207 65.3947 19.0516 67.7099 21.9208 67.7099C24.8311 67.7099 26.6756 65.3947 26.6756 62.5007C26.6756 59.6067 24.8311 57.2915 21.9208 57.2915ZM21.9208 66.7176C19.6254 66.7176 18.3547 64.6918 18.3547 62.4593C18.3547 60.2682 19.6664 58.2424 21.9208 58.2424C24.2572 58.2424 25.5279 60.2682 25.5279 62.4593C25.5279 64.6918 24.2162 66.7176 21.9208 66.7176Z'
			fill='#5E6768'
		/>
		<path
			d='M33.9717 57.2915C32.537 57.2915 31.1434 58.1597 30.4466 59.0279V57.5396H29.3809V67.4618H30.4466V59.9788C31.0614 59.0692 32.3731 58.2837 33.6438 58.2837C35.0784 58.2837 36.0211 58.8625 36.0211 60.7643V67.4618H37.0869V60.5162C37.0459 58.2837 35.9802 57.2915 33.9717 57.2915Z'
			fill='#5E6768'
		/>
		<path
			d='M42.6612 66.7178C41.9644 66.7178 41.6364 66.1803 41.6364 65.3534V58.4492H43.6449V57.4983H41.6364V54.811H40.5707V57.5397H38.9312V58.4905H40.5707V65.5602C40.5707 66.9245 41.1856 67.71 42.4972 67.71C43.317 67.71 43.7679 67.4619 44.1778 67.0898L43.7679 66.263C43.522 66.511 43.1121 66.7178 42.6612 66.7178Z'
			fill='#5E6768'
		/>
		<path
			d='M50.122 57.2915C47.3757 57.2915 45.4082 59.6067 45.4082 62.5007C45.4082 65.56 47.4577 67.7099 50.3269 67.7099C51.9665 67.7099 53.1962 67.1311 54.1799 66.0975L53.6471 65.3947C52.8273 66.3042 51.6796 66.759 50.4499 66.759C48.1135 66.759 46.7199 64.9812 46.5969 62.8314H54.8358V62.542C54.7538 59.6894 53.1142 57.2915 50.122 57.2915ZM46.5559 62.0046C46.6379 60.2682 47.7856 58.2424 50.122 58.2424C52.5813 58.2424 53.6881 60.3509 53.6881 62.0046H46.5559Z'
			fill='#5E6768'
		/>
		<path
			d='M62.0903 57.2915C60.6557 57.2915 59.2621 58.1597 58.5652 59.0279V57.5396H57.4995V67.4618H58.5652V59.9788C59.1801 59.0692 60.4917 58.2837 61.7624 58.2837C63.197 58.2837 64.1398 58.8625 64.1398 60.7643V67.4618H65.2055V60.5162C65.1645 58.2837 64.0988 57.2915 62.0903 57.2915Z'
			fill='#5E6768'
		/>
		<path
			d='M70.7803 66.7178C70.0835 66.7178 69.7556 66.1803 69.7556 65.3534V58.4492H71.7641V57.4983H69.7556V54.811H68.6899V57.5397H67.0503V58.4905H68.6899V65.5602C68.6899 66.9245 69.3047 67.71 70.6164 67.71C71.4362 67.71 71.887 67.4619 72.2969 67.0898L71.887 66.263C71.6411 66.511 71.2312 66.7178 70.7803 66.7178Z'
			fill='#5E6768'
		/>
		<path
			d='M79.3469 59.3172C77.3384 58.7384 76.1497 58.325 76.1497 57.126C76.1497 55.8858 77.3384 55.0176 78.937 55.0176C80.4536 55.0176 81.6833 55.555 82.5031 56.5886L82.79 56.9607L84.1017 55.5137L83.8557 55.2656C82.708 53.9013 80.9865 53.1985 78.978 53.1985C76.1497 53.1985 74.1413 54.8935 74.1413 57.2501C74.1413 60.0614 76.6006 60.7229 78.7731 61.343C80.9455 61.9631 82.2981 62.4593 82.2981 63.9476C82.2981 64.4851 82.0522 66.2628 79.142 66.2628C77.0925 66.2628 75.7808 65.1052 75.166 64.4024L74.8791 64.0716L73.6084 65.56L73.8133 65.808C75.043 67.2964 76.9285 68.1232 79.06 68.1232C82.913 68.1232 84.2656 65.808 84.2656 63.8649C84.2656 60.7229 81.5194 59.9373 79.3469 59.3172Z'
			fill='#0085FF'
		/>
		<path
			d='M90.8648 58.6558C91.8896 58.6558 92.6274 59.0279 93.2422 59.8548L93.4881 60.2269L94.8408 59.0279L94.5949 58.7385C93.6111 57.4569 92.4224 56.9194 90.8238 56.9194C87.8316 56.9194 85.6592 59.276 85.6592 62.5007C85.6592 65.7668 87.8316 68.1233 90.8238 68.1233C92.4634 68.1233 93.6111 67.5445 94.5949 66.2629L94.8408 65.9735L93.5291 64.7746L93.2832 65.1466C92.6684 65.9735 91.9305 66.3869 90.9058 66.3869C88.9383 66.3869 87.5857 64.8572 87.5857 62.542C87.5857 60.1855 88.8973 58.6558 90.8648 58.6558Z'
			fill='#0085FF'
		/>
		<path
			d='M103.53 58.0771C102.71 57.2916 101.645 56.8782 100.497 56.8782C97.6277 56.8782 95.7422 59.152 95.7422 62.5008C95.7422 65.8496 97.6687 68.0821 100.497 68.0821C101.604 68.0821 102.71 67.6273 103.53 66.9245V67.834H105.375V57.1262H103.53V58.0771ZM97.6277 62.5008C97.6277 60.1443 98.8164 58.6559 100.661 58.6559C101.891 58.6559 102.997 59.4001 103.489 60.1856V64.8573C102.997 65.6015 101.891 66.3457 100.661 66.3457C98.8164 66.3457 97.6277 64.816 97.6277 62.5008Z'
			fill='#0085FF'
		/>
		<path
			d='M112.876 56.8782C111.688 56.8782 110.54 57.4156 109.72 58.0771V57.1262H107.875V67.834H109.72V60.0616C110.294 59.3174 111.401 58.6146 112.507 58.6146C113.901 58.6146 114.475 59.2347 114.475 60.6817V67.7927H116.319V60.475C116.36 58.1598 115.172 56.8782 112.876 56.8782Z'
			fill='#0085FF'
		/>
	</svg>
);

export const LogoFullWhite = () => (
	<svg
		width='120'
		height='72'
		viewBox='0 0 120 72'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M59.9998 3.91797C58.6881 3.91797 57.4174 4.042 56.1878 4.24871C55.204 4.41408 54.2203 4.66214 53.2775 4.99288C46.9242 7.1427 41.9235 12.1865 39.792 18.5946C39.4641 19.5455 39.2182 20.5378 39.0542 21.53L41.3496 23.8452L43.3171 25.8296L51.474 34.0568C53.3595 35.9173 55.7779 37.1575 58.5242 37.4883C59.016 37.5296 59.4669 37.571 59.9588 37.571C65.9022 37.571 70.903 33.1886 71.8867 27.4833L69.4683 25.0441C69.4683 25.1268 69.4683 25.2508 69.4683 25.3335C69.4683 30.6254 65.2054 34.925 59.9588 34.925C57.4174 34.925 55.122 33.9328 53.4005 32.2791L47.9079 26.7392L41.8415 20.6204C43.522 13.9643 48.7277 8.75507 55.327 7.01867C56.3517 6.77062 57.3765 6.5639 58.4832 6.48122C58.975 6.43987 59.4669 6.43988 59.9998 6.43988C69.5503 6.43988 77.4613 13.7162 78.568 23.0597L81.2733 25.7883C81.2733 25.6229 81.2733 25.4575 81.2733 25.2922C81.2733 13.5508 71.7227 3.91797 59.9998 3.91797Z'
			fill='white'
		/>
		<path
			d='M78.6502 26.9045L76.7647 25.0027L68.5668 16.7755C68.6898 16.8996 68.7718 17.0236 68.8947 17.1063C67.0092 14.9978 64.3859 13.5921 61.4347 13.2614C60.9428 13.2201 60.4919 13.1787 60 13.1787C54.0566 13.1787 49.0559 17.561 48.0721 23.2663L50.4905 25.7056C50.4905 25.6229 50.4905 25.4989 50.4905 25.4162C50.4905 20.1243 54.7534 15.8246 60 15.8246C62.5414 15.8246 64.8368 16.8169 66.5583 18.4706C66.5993 18.5119 66.5993 18.5119 66.6403 18.5533C66.6813 18.5946 66.6813 18.5946 66.7223 18.636L72.0099 23.9692L73.9774 25.9536L78.1173 30.1292C76.4368 36.7854 71.2311 42.0359 64.6318 43.731C63.6071 43.9791 62.5824 44.1858 61.4757 44.2685C60.9838 44.3098 60.4919 44.3098 59.959 44.3098C50.4085 44.3098 42.4976 37.0335 41.3908 27.69L38.6855 24.9614C38.6855 25.1268 38.6855 25.2921 38.6855 25.4575C38.6855 37.2815 48.2361 46.9144 59.959 46.9144C61.2707 46.9144 62.5414 46.7904 63.7711 46.5837C64.7548 46.4183 65.7386 46.1702 66.6813 45.8395C73.0347 43.6897 78.0354 38.6458 80.1668 32.2377C80.4947 31.2868 80.7407 30.2946 80.9046 29.3024L78.6502 26.9045Z'
			fill='white'
		/>
		<path
			d='M10.5259 66.635C7.32869 66.635 4.86933 64.1544 4.86933 60.5989C4.86933 57.0434 7.36968 54.6042 10.5259 54.6042C12.1654 54.6042 13.6411 55.3897 14.5018 56.5887L15.4856 55.9685C14.3379 54.5215 12.6983 53.5293 10.5259 53.5293C6.67286 53.5293 3.63965 56.4233 3.63965 60.5989C3.63965 64.7745 6.67286 67.6685 10.5259 67.6685C12.6983 67.6685 14.2559 66.7176 15.4856 65.2293L14.5018 64.6092C13.6411 65.8081 12.1245 66.635 10.5259 66.635Z'
			fill='white'
		/>
		<path
			d='M21.9208 57.2915C19.0516 57.2915 17.207 59.6067 17.207 62.5007C17.207 65.3947 19.0516 67.7099 21.9208 67.7099C24.8311 67.7099 26.6756 65.3947 26.6756 62.5007C26.6756 59.6067 24.8311 57.2915 21.9208 57.2915ZM21.9208 66.7176C19.6254 66.7176 18.3547 64.6918 18.3547 62.4593C18.3547 60.2682 19.6664 58.2424 21.9208 58.2424C24.2572 58.2424 25.5279 60.2682 25.5279 62.4593C25.5279 64.6918 24.2162 66.7176 21.9208 66.7176Z'
			fill='white'
		/>
		<path
			d='M33.9717 57.2915C32.537 57.2915 31.1434 58.1597 30.4466 59.0279V57.5396H29.3809V67.4618H30.4466V59.9788C31.0614 59.0692 32.3731 58.2837 33.6438 58.2837C35.0784 58.2837 36.0211 58.8625 36.0211 60.7643V67.4618H37.0869V60.5162C37.0459 58.2837 35.9802 57.2915 33.9717 57.2915Z'
			fill='white'
		/>
		<path
			d='M42.6612 66.7178C41.9644 66.7178 41.6364 66.1803 41.6364 65.3534V58.4492H43.6449V57.4983H41.6364V54.811H40.5707V57.5397H38.9312V58.4905H40.5707V65.5602C40.5707 66.9245 41.1856 67.71 42.4972 67.71C43.317 67.71 43.7679 67.4619 44.1778 67.0898L43.7679 66.263C43.522 66.511 43.1121 66.7178 42.6612 66.7178Z'
			fill='white'
		/>
		<path
			d='M50.122 57.2915C47.3757 57.2915 45.4082 59.6067 45.4082 62.5007C45.4082 65.56 47.4577 67.7099 50.3269 67.7099C51.9665 67.7099 53.1962 67.1311 54.1799 66.0975L53.6471 65.3947C52.8273 66.3042 51.6796 66.759 50.4499 66.759C48.1135 66.759 46.7199 64.9812 46.5969 62.8314H54.8358V62.542C54.7538 59.6894 53.1142 57.2915 50.122 57.2915ZM46.5559 62.0046C46.6379 60.2682 47.7856 58.2424 50.122 58.2424C52.5813 58.2424 53.6881 60.3509 53.6881 62.0046H46.5559Z'
			fill='white'
		/>
		<path
			d='M62.0903 57.2915C60.6557 57.2915 59.2621 58.1597 58.5652 59.0279V57.5396H57.4995V67.4618H58.5652V59.9788C59.1801 59.0692 60.4917 58.2837 61.7624 58.2837C63.197 58.2837 64.1398 58.8625 64.1398 60.7643V67.4618H65.2055V60.5162C65.1645 58.2837 64.0988 57.2915 62.0903 57.2915Z'
			fill='white'
		/>
		<path
			d='M70.7803 66.7178C70.0835 66.7178 69.7556 66.1803 69.7556 65.3534V58.4492H71.7641V57.4983H69.7556V54.811H68.6899V57.5397H67.0503V58.4905H68.6899V65.5602C68.6899 66.9245 69.3047 67.71 70.6164 67.71C71.4362 67.71 71.887 67.4619 72.2969 67.0898L71.887 66.263C71.6411 66.511 71.2312 66.7178 70.7803 66.7178Z'
			fill='white'
		/>
		<path
			d='M79.3469 59.3172C77.3384 58.7384 76.1497 58.325 76.1497 57.126C76.1497 55.8858 77.3384 55.0176 78.937 55.0176C80.4536 55.0176 81.6833 55.555 82.5031 56.5886L82.79 56.9607L84.1017 55.5137L83.8557 55.2656C82.708 53.9013 80.9865 53.1985 78.978 53.1985C76.1497 53.1985 74.1413 54.8935 74.1413 57.2501C74.1413 60.0614 76.6006 60.7229 78.7731 61.343C80.9455 61.9631 82.2981 62.4593 82.2981 63.9476C82.2981 64.4851 82.0522 66.2628 79.142 66.2628C77.0925 66.2628 75.7808 65.1052 75.166 64.4024L74.8791 64.0716L73.6084 65.56L73.8133 65.808C75.043 67.2964 76.9285 68.1232 79.06 68.1232C82.913 68.1232 84.2656 65.808 84.2656 63.8649C84.2656 60.7229 81.5194 59.9373 79.3469 59.3172Z'
			fill='white'
		/>
		<path
			d='M90.8648 58.6558C91.8896 58.6558 92.6274 59.0279 93.2422 59.8548L93.4881 60.2269L94.8408 59.0279L94.5949 58.7385C93.6111 57.4569 92.4224 56.9194 90.8238 56.9194C87.8316 56.9194 85.6592 59.276 85.6592 62.5007C85.6592 65.7668 87.8316 68.1233 90.8238 68.1233C92.4634 68.1233 93.6111 67.5445 94.5949 66.2629L94.8408 65.9735L93.5291 64.7746L93.2832 65.1466C92.6684 65.9735 91.9305 66.3869 90.9058 66.3869C88.9383 66.3869 87.5857 64.8572 87.5857 62.542C87.5857 60.1855 88.8973 58.6558 90.8648 58.6558Z'
			fill='white'
		/>
		<path
			d='M103.53 58.0771C102.71 57.2916 101.645 56.8782 100.497 56.8782C97.6277 56.8782 95.7422 59.152 95.7422 62.5008C95.7422 65.8496 97.6687 68.0821 100.497 68.0821C101.604 68.0821 102.71 67.6273 103.53 66.9245V67.834H105.375V57.1262H103.53V58.0771ZM97.6277 62.5008C97.6277 60.1443 98.8164 58.6559 100.661 58.6559C101.891 58.6559 102.997 59.4001 103.489 60.1856V64.8573C102.997 65.6015 101.891 66.3457 100.661 66.3457C98.8164 66.3457 97.6277 64.816 97.6277 62.5008Z'
			fill='white'
		/>
		<path
			d='M112.876 56.8782C111.688 56.8782 110.54 57.4156 109.72 58.0771V57.1262H107.875V67.834H109.72V60.0616C110.294 59.3174 111.401 58.6146 112.507 58.6146C113.901 58.6146 114.475 59.2347 114.475 60.6817V67.7927H116.319V60.475C116.36 58.1598 115.172 56.8782 112.876 56.8782Z'
			fill='white'
		/>
	</svg>
);

export const LogoShortColor = () => (
	<svg
		width='48'
		height='48'
		viewBox='0 0 48 48'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'>
		<path
			fill-rule='evenodd'
			clip-rule='evenodd'
			d='M19.7263 0.369409C21.1123 0.138641 22.5444 0.000183105 24.0227 0.000183105C37.2354 0.000183105 47.9996 10.7539 47.9996 23.8615V24.4154L44.9505 21.3692C43.7031 10.9386 34.7869 2.81554 24.0227 2.81554H24.0227C23.4221 2.81554 22.8678 2.81554 22.3134 2.8617C21.0661 2.954 19.9111 3.18477 18.7561 3.46169C11.3182 5.40014 5.45108 11.2155 3.55695 18.6462L10.3943 25.4769L16.5848 31.6615C18.5252 33.5076 21.1122 34.6153 23.9765 34.6153C29.8899 34.6153 34.6945 29.8153 34.6945 23.9077V23.5846L37.4202 26.3077C36.3114 32.6768 30.6753 37.5691 23.9765 37.5691C23.4619 37.5691 22.9871 37.5293 22.4782 37.4867C22.4389 37.4834 22.3994 37.4801 22.3596 37.4768C19.2643 37.1076 16.5386 35.723 14.4135 33.6461L5.22009 24.4615L3.00258 22.2462L0.415482 19.6616C0.600274 18.5539 0.877461 17.4462 1.24705 16.3847C3.64935 9.23088 9.28553 3.60015 16.4462 1.20017C17.5088 0.830945 18.6175 0.554023 19.7263 0.369409ZM42.9181 23.5384L45.0432 25.6614L47.5841 28.3383C47.3993 29.446 47.1221 30.5537 46.7525 31.6152C44.3502 38.769 38.7141 44.3997 31.5533 46.7997C30.4908 47.1689 29.382 47.4459 28.2733 47.6305C26.8873 47.8612 25.4552 47.9997 23.9768 47.9997C10.7642 47.9997 0 37.2459 0 24.046V23.4922L3.04908 26.5383C4.29643 36.969 13.2127 45.092 23.9768 45.092C24.5774 45.092 25.1318 45.092 25.6862 45.0459C26.9335 44.9536 28.0885 44.7228 29.2434 44.4459C36.6813 42.5536 42.5485 36.6921 44.4426 29.2614L39.7766 24.5999L37.5591 22.3845L31.5995 16.4307L31.5071 16.3384L31.4148 16.2461C29.4744 14.4 26.8873 13.2923 24.023 13.2923C18.1097 13.2923 13.3051 18.0922 13.3051 23.9999V24.323L10.5794 21.5999C11.6881 15.2307 17.3243 10.3385 24.023 10.3385C24.5376 10.3385 25.0124 10.3782 25.5213 10.4208C25.5606 10.4241 25.6002 10.4275 25.64 10.4308C28.9576 10.799 31.9076 12.3611 34.0315 14.7048L42.9181 23.5384Z'
			fill='#0085FF'
		/>
	</svg>
);
export const LogoShortWhite = () => (
	<svg
		width='48'
		height='48'
		viewBox='0 0 48 48'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'>
		<path
			fill-rule='evenodd'
			clip-rule='evenodd'
			d='M19.7263 0.36947C21.1123 0.138702 22.5444 0.000244141 24.0227 0.000244141C37.2354 0.000244141 47.9996 10.754 47.9996 23.8616V24.4154L44.9505 21.3693C43.7031 10.9386 34.7869 2.81561 24.0227 2.81561H24.0227C23.4221 2.81561 22.8678 2.81561 22.3134 2.86176C21.0661 2.95407 19.9111 3.18483 18.7561 3.46175C11.3182 5.4002 5.45108 11.2155 3.55695 18.6462L10.3943 25.477L16.5848 31.6615C18.5252 33.5077 21.1122 34.6153 23.9765 34.6153C29.8899 34.6153 34.6945 29.8154 34.6945 23.9077V23.5847L37.4202 26.3077C36.3114 32.6769 30.6753 37.5692 23.9765 37.5692C23.4619 37.5692 22.9871 37.5294 22.4782 37.4868C22.4389 37.4835 22.3994 37.4802 22.3596 37.4769C19.2643 37.1076 16.5386 35.723 14.4135 33.6461L5.22009 24.4616L3.00258 22.2462L0.415482 19.6616C0.600274 18.5539 0.877461 17.4463 1.24705 16.3847C3.64935 9.23094 9.28553 3.60021 16.4462 1.20023C17.5088 0.831006 18.6176 0.554084 19.7263 0.36947ZM42.9181 23.5384L45.0432 25.6615L47.5841 28.3384C47.3993 29.4461 47.1221 30.5537 46.7525 31.6153C44.3502 38.7691 38.7141 44.3998 31.5533 46.7998C30.4908 47.169 29.382 47.4459 28.2733 47.6305C26.8873 47.8613 25.4552 47.9998 23.9768 47.9998C10.7642 47.9998 0 37.246 0 24.0461V23.4923L3.04908 26.5384C4.29643 36.9691 13.2127 45.0921 23.9768 45.0921C24.5774 45.0921 25.1318 45.0921 25.6862 45.0459C26.9335 44.9536 28.0885 44.7229 29.2434 44.4459C36.6813 42.5537 42.5485 36.6922 44.4426 29.2615L39.7766 24.5999L37.5591 22.3846L31.5995 16.4308L31.5071 16.3385L31.4148 16.2462C29.4744 14.4 26.8873 13.2923 24.023 13.2923C18.1097 13.2923 13.3051 18.0923 13.3051 24V24.323L10.5794 21.6C11.6881 15.2308 17.3243 10.3385 24.023 10.3385C24.5376 10.3385 25.0124 10.3783 25.5213 10.4209C25.5606 10.4242 25.6002 10.4275 25.64 10.4308C28.9576 10.7991 31.9076 12.3611 34.0315 14.7048L42.9181 23.5384Z'
			fill='white'
		/>
	</svg>
);
