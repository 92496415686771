import React, { useRef, useState, useEffect } from "react";
import classnames from "classnames";
import useHover from "@react-hook/hover";

import { Utils, NavIcons } from "../export";
import { FormattedMessage, useIntl } from "react-intl";

export const DropDown = ({
	options = [],
	onOptionClick,
	className = "",
	activeOptionValue,
	truncateTitle = 0,
}) => {
	const intl = useIntl();
	const [isOpen, setIsOpen] = useState(false);

	const headerRef = useRef(null);
	const isHoverHeader = useHover(headerRef, {
		enterDelay: 0,
		leaveDelay: 0,
	});

	const bodyRef = useRef(null);
	const isHoverBody = useHover(bodyRef, {
		enterDelay: 0,
		leaveDelay: 0,
	});

	// useClickAway(labelRef, (e) => {
	// 	setTooltip(false);
	// 	handleLabelHover && handleLabelHover(false, input.name);
	// });

	// useEffect(() => {
	// setIsOpen(isHoverHeader);
	// handleLabelHover && handleLabelHover(isHoverLabel, input.name);
	// }, [isHoverHeader]);

	const activeOption = options.find(
		(option) => option.value === activeOptionValue,
	);
	return (
		<div className={`drop-down ${className}`} ref={headerRef}>
			<header
				onClick={() => setIsOpen(!isOpen)}
				className={`row-start-center drop-down_header ${className}_header`}>
				{Utils.truncateString({
					str: intl.formatMessage({
						id: activeOption.activeTitle || activeOption.title,
					}),
					max: truncateTitle,
				})}
				<NavIcons.DropArrow direction={"down"} />
			</header>

			<main
				className={classnames(`drop-down_body ${className}_body`, {
					"drop-down_body--open": isHoverHeader || isHoverBody || isOpen,
				})}
				ref={bodyRef}>
				{options.map((option, i) => {
					const { title, value } = option;
					return (
						<li
							onClick={() => {
								onOptionClick(option);
								setIsOpen(false);
							}}
							className={classnames("drop-down_item", {
								"drop-down_item--active": value === activeOptionValue,
							})}
							key={i}>
							<FormattedMessage id={title} />
						</li>
					);
				})}
			</main>
		</div>
	);
};

export default DropDown;
