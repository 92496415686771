import { lazy } from "react";
const RootPage = lazy(() => import("./Homepage/RootPage"));
const VideoContentPage = lazy(() => import("./VideoContent/VideoContentPage"));
const PiracyRating = lazy(() => import("./PiracyRating/PiracyRatingPage"));
const PricingPage = lazy(() => import("./Pricing/PricingPage"));
const PrivacyPage = lazy(() => import("./PrivacyPage/PrivacyPage"));
export const AppRoutes = [
	{
		path: "/",
		exact: true,
		Component: RootPage,
	},
	{
		path: "/protect/video",
		exact: true,
		Component: VideoContentPage,
	},
	{
		path: "/video-content",
		exact: true,
		Component: VideoContentPage,
	},
	{
		path: "/piracy-rating",
		exact: true,
		Component: PiracyRating,
	},
	{
		path: "/pricing",
		exact: true,
		Component: PricingPage,
	},
	{
		path: "/privacy",
		exact: true,
		Component: PrivacyPage,
	},
];
