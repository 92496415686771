import React from "react";
import { useIntl } from "react-intl";

export const TableHeader = ({ cols, className = "", cellClass = "" }) => {
	const intl = useIntl();
	// const colsCount = intl.formatMessage({
	// 	id: `${casesKey}.itemsCount`,
	// });
	return (
		<TableRow className={`${className} table-header row-end`}>
			{new Array(2).fill(0).map((col, i) => {
				const number = i + 1;
				const title = intl.formatMessage({
					id: `${cols}.${number}`,
				});
				return (
					<TableCell className={cellClass} key={i}>
						<span
							dangerouslySetInnerHTML={{
								__html: title,
							}}
						/>
					</TableCell>
				);
			})}
		</TableRow>
	);
};

//border-bottom
export const TableRow = ({ children, className = "" }) => (
	<div className={`table-row ${className}`}>{children}</div>
);

export const TableCell = ({ children, className = "" }) => (
	<div className={`table-cell ${className} `}>{children}</div>
);
