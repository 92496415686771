import React, { useRef } from "react";
import { useMouseHovered, useWindowSize } from "react-use";
import { useIntl } from "react-intl";
import classnames from "classnames";
import useHover from "@react-hook/hover";

import { CircleMask } from "../export";

const ContentBlocks = ({ blocks }) => {
	return (
		<div className={`row-center-stretch content-blocks`}>
			{blocks.map((block, i) => {
				return <Block block={block} key={i} />;
			})}
		</div>
	);
};

export default ContentBlocks;

const Block = ({ block }) => {
	const { width } = useWindowSize();
	const isMobile = width <= 768;

	const intl = useIntl();
	const { intlKey, half, className = "", bgImg } = block;
	const title = intl.formatMessage({
		id: `${intlKey}.title`,
	});
	const subtitle = intl.formatMessage({
		id: `${intlKey}.subtitle`,
	});
	const bgImgUrl = intl.formatMessage({ id: bgImg });

	const clipAreaRef = React.useRef(null);
	const { elX, elY, elW, elH, docY } = useMouseHovered(clipAreaRef, {
		bound: true,
		whenHovered: false,
	});
	const cursorY = elY > -160 ? 160 + elY : 0;
	const cursorX = elX > -160 ? 160 + elX : 0;
	const transformMask = `translate(${-160 + cursorX}px, ${-160 + cursorY}px)`;

	// TITLE CLIP PATH
	const blockHeight = width <= 768 ? 240 : width <= 998 ? 295 : 368;

	const titleRef = useRef(null);
	const { elX: titleX, elY: titleY } = useMouseHovered(titleRef, {
		bound: false,
		whenHovered: false,
	});
	const titleClipPath =
		titleX > -160 && elY > 0 && elY < blockHeight && !isMobile
			? `circle(160px at ${titleX}px ${titleY}px)`
			: `unset`;

	// SUBTITLE CLIP PATH
	const subtitleRef = useRef(null);
	const { elX: subtitleX, elY: subtitleY } = useMouseHovered(subtitleRef, {
		bound: false,
		whenHovered: false,
	});
	const subtitleClipPath =
		subtitleX > -160 && elX > 0 && elY < blockHeight && !isMobile
			? `circle(160px at ${subtitleX}px ${subtitleY}px)`
			: `unset`;

	return (
		<div
			ref={clipAreaRef}
			style={{
				backgroundImage: `url(${bgImgUrl})`,
			}}
			className={classnames(`row-bw-center content-blocks_item ${className}`, {
				half: half,
			})}>
			<div className='overlay' />
			<CircleMask
				transform={transformMask}
				className='content-blocks_item-mask'
			/>

			<div
				style={{
					zIndex: "5",
				}}
				className={classnames(
					`col-center-center content-blocks_item-inner ${className} `,
				)}>
				<div className='col-center content-blocks_item_title h3'>
					<span
						dangerouslySetInnerHTML={{
							__html: title,
						}}
						style={{
							color: "black",
							position: "absolute",
							zIndex: "0",
							width: "max-content",
						}}
					/>
					<span
						ref={titleRef}
						dangerouslySetInnerHTML={{
							__html: title,
						}}
						style={{
							clipPath: titleClipPath,
							color: "white",
							// color: width < 640 ? "#151515" : "white",
						}}
					/>
				</div>
				<p className='content-blocks_item_subtitle'>
					<span
						dangerouslySetInnerHTML={{
							__html: subtitle,
						}}
						style={{
							color: "#94A0B1",
							position: "absolute",
							zIndex: "0",
							width: "max-content",
						}}
					/>
					<span
						ref={subtitleRef}
						dangerouslySetInnerHTML={{
							__html: subtitle,
						}}
						style={{
							clipPath: subtitleClipPath,
							color: "white",

							// color: width < 640 ? "#94A0B1" : "white",
						}}
					/>
				</p>
			</div>
		</div>
	);
};

// <svg
// className='content-blocks_clip-area'
// width='100%'
// height='100%'
// fill='white'
// xmlns='http://www.w3.org/2000/svg'>
// <defs>
// 	<mask id='myMask'>
// 		<rect width='100%' height='100%' fill='white' />
// 		<circle
// 			cx={-160 + hiddenW}
// 			cy={-160 + hiddenHeight}
// 			r='160'
// 			fill='black'
// 		/>
// 	</mask>
// </defs>

// <g id='startColor' fill='#94c356' font-size='100'>
// 	<text class='theCount' text-anchor='end' x='220' y='140'>
// 		100
// 	</text>
// 	<text text-anchor='start' x='230' y='140'>
// 		%
// 	</text>
// </g>

// <rect
// 	x='0'
// 	y='0'
// 	width='100%'
// 	height='100%'
// 	fill='white'
// 	mask='url(#myMask)'
// />
// </svg>
