import React from "react";
import classnames from "classnames";
import { useIntl } from "react-intl";

export const Input = ({
	placeholder,
	errorText,
	input = {},
	meta,
	submitted,
	...rest
}) => {
	const intl = useIntl();
	const placeholderText = intl.formatMessage({ id: placeholder });

	const isErorr = meta.error && submitted;
	//  && !meta.active && meta.dirty;

	return (
		<div
			className={classnames("input-wrapper", {
				"input-wrapper--error": isErorr,
			})}>
			{(meta.active || input.value) && (
				<label className='label' for={input.name}>
					{placeholderText}
				</label>
			)}
			<input
				type='text'
				className={classnames("input", {
					"input--active": meta.active,
					"input--error": isErorr,
				})}
				placeholder={meta.active ? "" : placeholderText}
				{...input}
				{...rest}
			/>
			{isErorr && <p className='input_error-text'>{errorText}</p>}
		</div>
	);
};

export default Input;
