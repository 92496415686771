import React, { useRef } from "react";
import classnames from "classnames";
import { useClickAway } from "react-use";

const Tooltip = ({ isOpen, title, className = "", text, close }) => {
	const ref = useRef(null);

	useClickAway(ref, (e) => {
		close();
	});

	return (
		<div
			ref={ref}
			className={classnames("tooltip", className, {
				"tooltip--open": isOpen,
			})}>
			<div className='tooltip-content'>
				<span className='tooltip-arrow' />

				{title && <h2 className='tooltip_title'>{title}</h2>}
				<p className='tooltip_desc'>{text}</p>
			</div>
		</div>
	);
};

export default Tooltip;
