const getDirectionClassName = (direction) => {
	const directionsClassNames = {
		down: "rotate-90",
		right: "",
		left: "rotate-180",
	};

	return directionsClassNames[direction];
};

export const DropArrow = ({
	fill = "inherit",
	className = "",
	direction = "down",
}) => (
	<svg
		className={`${getDirectionClassName(direction)} ${className}`}
		width='24'
		height='24'
		viewBox='0 0 24 24'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M9.58594 9.00003L10.293 9.70714L12.5859 12L10.293 14.2929L9.58594 15L11.0002 16.4142L11.7073 15.7071L14.7073 12.7071L15.4144 12L14.7073 11.2929L11.7073 8.29292L11.0002 7.58582L9.58594 9.00003Z'
			fill={fill}
		/>
	</svg>
);

export const DropArrowBig = ({
	fill = "inherit",
	className = "",
	direction = "right",
	onClick = () => {},
}) => {
	return (
		<svg
			onClick={onClick}
			className={`${getDirectionClassName(direction)} ${className}`}
			width='24'
			height='24'
			viewBox='0 0 24 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				fill-rule='evenodd'
				clip-rule='evenodd'
				d='M6.58579 20L7.29289 19.2929L14.5858 12L7.29289 4.70711L6.58579 4L8 2.58579L8.70711 3.29289L16.7071 11.2929L17.4142 12L16.7071 12.7071L8.70711 20.7071L8 21.4142L6.58579 20Z'
				fill={fill}
			/>
		</svg>
	);
};

export const Arrow = ({
	fill = "inherit",
	className = "",
	direction = "right",
}) => (
	<svg
		className={`${getDirectionClassName(direction)} ${className}`}
		width='24'
		height='24'
		viewBox='0 0 24 24'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'>
		<path
			fill-rule='evenodd'
			clip-rule='evenodd'
			d='M12.0858 16.5L12.7929 15.7929L15.5858 13H6H5V11H6H15.5858L12.7929 8.20711L12.0858 7.5L13.5 6.08579L14.2071 6.79289L18.7071 11.2929L19.4142 12L18.7071 12.7071L14.2071 17.2071L13.5 17.9142L12.0858 16.5Z'
			fill={fill}
		/>
	</svg>
);

export const ArrowBig = ({
	fill = "inherit",
	className = "",
	direction = "right",
}) => (
	<svg
		className={`${getDirectionClassName(direction)} ${className}`}
		width='24'
		height='24'
		viewBox='0 0 24 24'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'>
		<path
			fill-rule='evenodd'
			clip-rule='evenodd'
			d='M13.0858 18.5L13.7929 17.7929L18.5858 13L3 13H2L2 11H3L18.5858 11L13.7929 6.20711L13.0858 5.5L14.5 4.08579L15.2071 4.79289L21.7071 11.2929L22.4142 12L21.7071 12.7071L15.2071 19.2071L14.5 19.9142L13.0858 18.5Z'
			fill={fill}
		/>
	</svg>
);

export const Refresh = ({ fill = "inherit", className = "" }) => (
	<svg
		width='24'
		height='24'
		className={className}
		viewBox='0 0 24 24'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'>
		<path
			fill-rule='evenodd'
			clip-rule='evenodd'
			d='M12 4C9.04752 4 6.5151 5.60086 5.1339 8H6.5H7.5V10H6.5H3H2V9V5V4H4V5V6.08035C5.80217 3.61126 8.6856 2 12 2C15.9359 2 19.2641 4.27225 20.8955 7.55497L21.3405 8.45048L19.5495 9.34055L19.1045 8.44503C17.7905 5.80088 15.1315 4 12 4ZM4.45048 14.6595L4.89551 15.555C6.20955 18.1991 8.8685 20 12 20C14.5202 20 16.7343 18.8336 18.1876 17H17H16V15H17H19.9798C19.9937 14.9997 20.0077 14.9997 20.0217 15H20.5H21.5V16V19.5V20.5H19.5V19.5V18.5505C17.6875 20.6596 15.023 22 12 22C8.06405 22 4.73586 19.7278 3.10449 16.445L2.65945 15.5495L4.45048 14.6595Z'
			fill={fill}
		/>
	</svg>
);

export const Close = ({
	fill = "inherit",
	className = "",
	size = 24,
	viewBox = 24,
}) => (
	<svg
		width={size}
		height={size}
		className={className}
		viewBox={`0 0 ${viewBox} ${viewBox}`}
		fill='none'
		xmlns='http://www.w3.org/2000/svg'>
		<path
			fill-rule='evenodd'
			clip-rule='evenodd'
			d='M4.70711 3.29289L4 2.58579L2.58579 4L3.29289 4.70711L10.5858 12L3.29289 19.2929L2.58579 20L4 21.4142L4.70711 20.7071L12 13.4142L19.2929 20.7071L20 21.4142L21.4142 20L20.7071 19.2929L13.4142 12L20.7071 4.70711L21.4142 4L20 2.58579L19.2929 3.29289L12 10.5858L4.70711 3.29289Z'
			fill={fill}
		/>
	</svg>
);
