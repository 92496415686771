import React from "react";
import classnames from "classnames";

import { NavIcons } from "../../compoents/export";

const Slider = ({ className = "" }) => {
	return (
		<div className={`slider`}>
			<SliderArrows />
		</div>
	);
};

export default Slider;

export const SliderArrows = ({
	className = "",
	gray,
	black,
	handleSlideChange,
}) => {
	return (
		<div
			className={classnames("row slider-arrows", {
				"slider-arrows--gray": gray,
				"slider-arrows--black": black,
			})}>
			<NavIcons.DropArrowBig
				direction='left'
				onClick={(e) => {
					e.stopPropagation();
					handleSlideChange(-1);
				}}
			/>
			<NavIcons.DropArrowBig
				onClick={(e) => {
					handleSlideChange(+1);
					e.stopPropagation();
				}}
			/>
		</div>
	);
};
