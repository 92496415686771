export const Video = ({ fill = "inherit", className = "" }) => (
	<svg
		width='24'
		height='24'
		className={className}
		viewBox='0 0 24 24'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'>
		<path
			fill-rule='evenodd'
			clip-rule='evenodd'
			d='M4 1C2.34315 1 1 2.34315 1 4V20C1 21.6569 2.34315 23 4 23H6H8H16H18H20C21.6569 23 23 21.6569 23 20V4C23 2.34315 21.6569 1 20 1H18H16H8H6H4ZM6 3H4C3.44772 3 3 3.44772 3 4V6H6V3ZM3 8V11H6V8H3ZM3 13V16H6V13H3ZM3 18V20C3 20.5523 3.44772 21 4 21H6V18H3ZM8 21H16V3H8V21ZM18 3V6L21 6V4C21 3.44772 20.5523 3 20 3H18ZM21 8L18 8V11H21V8ZM21 13H18V16H21V13ZM21 18H18V21H20C20.5523 21 21 20.5523 21 20V18Z'
			fill={fill}
		/>
	</svg>
);
