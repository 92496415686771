import React, { useState, useRef, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import cx from "classnames";
import {
	useWindowSize,
	useMeasure,
	useLockBodyScroll,
	useMouseWheel,
} from "react-use";
import useHover from "@react-hook/hover";
import { Link } from "react-router-dom";
import useRouter from "use-react-router";

import { Logo, NavIcons, Button, DropDown } from "../export";
import MobileMenu from "./MobileMenu";

const DropArrow = () => <NavIcons.DropArrow direction={"down"} />;

const Header = ({ togglLocale }) => {
	const intl = useIntl();
	const { width } = useWindowSize();
	const isMobile = width <= 768;
	const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
	useLockBodyScroll(isMobileMenuOpen);

	const submenuItemsCount =
		Number(
			intl.formatMessage({
				id: "header.protectMenu.itemsCount",
			}),
		) || 0;

	const menuItemRef = useRef(null);
	const isHoverMenuItem = useHover(menuItemRef, {
		enterDelay: 200,
		leaveDelay: 400,
	});
	const submenuRef = useRef(null);
	const isHoverSubMenu = useHover(submenuRef, {
		enterDelay: 0,
		leaveDelay: 300,
	});
	const isSubmenuOpen =
		submenuItemsCount !== 0 && (isHoverMenuItem || isHoverSubMenu);

	const { history } = useRouter();
	const path = history.location.pathname;

	const [headerHidden, setHeaderHidden] = useState(0);
	const { height } = useWindowSize();
	const [y, setY] = useState(window.scrollY);
	const handleNavigation = React.useCallback(
		(e) => {
			const window = e.currentTarget;
			if (y > window.scrollY) {
				// console.log("scrolling up");
				setHeaderHidden(false);
			} else if (y - window.scrollY < -10) {
				setHeaderHidden(true);
				// console.log("scrolling down");
			}
			setY(window.scrollY);
			// console.log("y", y, height);
		},
		[y],
	);

	const whiteBG =
		path === "/piracy-rating" ||
		path === "/pricing" ||
		path === "/privacy" ||
		height - y <= 420;
	const isColorLogo = isSubmenuOpen || whiteBG;
	const logoType =
		isColorLogo && isMobile
			? "shortColor"
			: isColorLogo && !isMobile
			? "fullColor"
			: isMobile
			? "shortWhite"
			: "fullWhite";

	useEffect(() => {
		setY(window.scrollY);
		window.addEventListener("scroll", handleNavigation);

		return () => {
			window.removeEventListener("scroll", handleNavigation);
		};
	}, [handleNavigation]);
	return (
		<>
			{" "}
			<HeaderWrapper
				whiteBG={whiteBG}
				headerHidden={headerHidden}
				isSubmenuOpen={isSubmenuOpen}>
				<HeaderTop
					logoType={logoType}
					menuItemRef={menuItemRef}
					togglLocale={togglLocale}
					isMobileMenuOpen={isMobileMenuOpen}
					setIsMobileMenuOpen={setIsMobileMenuOpen}
					submenuItemsCount={submenuItemsCount}
				/>

				<div
					className={cx("header-submenu border-bottom border-top", {
						"header-submenu--open": isSubmenuOpen,
					})}
					ref={submenuRef}>
					<HeaderSubmenu submenuItemsCount={submenuItemsCount} />
				</div>
			</HeaderWrapper>
			{isMobile && (
				<MobileMenu
					togglLocale={togglLocale}
					isMobileMenuOpen={isMobile && isMobileMenuOpen}
					setIsMobileMenuOpen={setIsMobileMenuOpen}
					submenuItemsCount={submenuItemsCount}
				/>
			)}
		</>
	);
};

export default Header;

export const HeaderTop = ({
	logoType,
	menuItemRef,
	togglLocale,
	isMobileMenuOpen,
	setIsMobileMenuOpen,
	submenuItemsCount,
}) => {
	const { history } = useRouter();
	const path = history.location.pathname;
	return (
		<div
			className={cx(
				"container",
				{
					cursor: path === "/",
				},
				"row-bw-center header-top",
			)}>
			<Logo className={cx("header-logo")} type={logoType} />

			<div
				className={cx("header-center", {
					cursor: path === "/",
				})}>
				<HeaderMenu
					menuItemRef={menuItemRef}
					submenuItemsCount={submenuItemsCount}
				/>
			</div>
			<div className={cx("row-bw-center header-right")}>
				{/* <LangSelect togglLocale={togglLocale} /> */}

				<ContactButton />
			</div>
			<button
				onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
				className={cx("header_burger-btn", {})}>
				{/* {isMobileMenuOpen && <NavIcons.Close size={29} viewBox={25} />} */}
			</button>
		</div>
	);
};

export const ContactButton = ({ onClick = () => {} }) => (
	<a href='#contact-form' onClick={onClick && onClick}>
		<Button
			options={{
				type: "secondaryOnDark",
				size: "small",
			}}
			text='header.contactBtn'
			className='header_contact-btn'
		/>
	</a>
);

export const HeaderWrapper = ({
	headerHidden,
	whiteBG,
	isSubmenuOpen,
	children,
}) => {
	const { history } = useRouter();
	const path = history.location.pathname;
	const headerAbsolute =
		path.includes("protect") || path === "/" || path.includes("video");

	return (
		<header
			className={cx("header", {
				cursor: path === "/",
				"header--white": isSubmenuOpen || whiteBG,
				"header--submenu-open": isSubmenuOpen,
				"header--absolute": headerAbsolute,
			})}
			style={{
				transform: headerHidden ? "translate(0, -100%)" : "translate(0, 0)",
			}}>
			<div
				className={cx("header-container", {
					cursor: path === "/",
				})}>
				{children}
			</div>
		</header>
	);
};

export const HeaderMenu = ({
	menuItemRef,
	setIsMobileMenuOpen,
	submenuItemsCount,
}) => {
	const intl = useIntl();
	const { width } = useWindowSize();
	const isMobile = width <= 768;
	const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);

	const { history } = useRouter();
	const path = history.location.pathname;

	const menu = [
		{
			title: "protect", //'protect',
			Icon: submenuItemsCount !== 0 ? DropArrow : () => <> </>,
			ref: true,
			onClick: () => {
				submenuItemsCount !== 0 && isMobile && setIsSubMenuOpen(!isSubMenuOpen);
			},
			link:
				submenuItemsCount === 0 &&
				intl.formatMessage({ id: "header.protect_link" }), // '/protect/video',
		},
		{
			title: "locking",
			link: "/piracy-rating",
		},
		{
			title: "pricing",
			link: "/pricing",
		},
	];

	return (
		<ul
			className={cx(
				{
					"header-menu--open": isSubMenuOpen,
					cursor: path === "/",
				},
				"row-bw-center header-menu",
			)}>
			{menu.map((item, i) => {
				const {
					title,
					Icon = () => <> </>,
					ref,
					link,
					onClick = () => {},
				} = item;
				return (
					<Link
						to={link}
						onClick={onClick && onClick}
						ref={ref && menuItemRef}
						className={cx("row-start-center header-menu_item")}
						key={i}>
						<a className={cx("row-start-center link")}>
							<FormattedMessage id={`header.${title}`} />
							<Icon />
						</a>
						{ref && isMobile && isSubMenuOpen && (
							<HeaderSubmenu
								isSubMenuOpen={isSubMenuOpen}
								submenuItemsCount={submenuItemsCount}
								closeMenu={() => {
									setIsMobileMenuOpen(false);
									setIsSubMenuOpen(false);
								}}
							/>
						)}
					</Link>
				);
			})}
		</ul>
	);
};

export const HeaderSubmenu = ({ submenuItemsCount, closeMenu }) => {
	const intl = useIntl();

	const submenuItems = new Array(Number(submenuItemsCount)).fill(0); // 3 -> submenuItemsCount

	const { history } = useRouter();
	const path = history.location.pathname;

	const [subMenuRef, { height: subMenuHeight }] = useMeasure();

	// console.log("subMenuHeight", subMenuHeight);
	const handleLinkClick = (link) => {
		closeMenu && closeMenu();
		window.location.replace(`/protect/${link}`);
	};

	if (submenuItemsCount === 0) {
		return null;
	}
	return (
		<ul
			ref={subMenuRef}
			className={cx(`row-bw-center header-submenu_list`, {
				cursor: path === "/",
			})}>
			{submenuItems.map((item, i) => {
				// const { title, link } = item;
				const number = i + 1;
				const title = intl.formatMessage({
					id: `header.protectMenu.${number}.title`,
				});
				const link = intl.formatMessage({
					id: `header.protectMenu.${number}.link`,
				});
				return (
					<div
						onClick={() => handleLinkClick(link)}
						className={cx(`row-start-center header-submenu_item`, {
							cursor: path === "/",
						})}
						key={i}>
						<p dangerouslySetInnerHTML={{ __html: title }} />
					</div>
				);
			})}
		</ul>
	);
};

export const LangSelect = ({ togglLocale }) => {
	const intl = useIntl();
	const currentLang = intl.locale;

	const { history } = useRouter();
	const path = history.location.pathname;

	const langsOptions = [
		{ title: "langs.ru.full", activeTitle: "langs.ru.short", value: "ru" },
		{ title: "langs.en.full", activeTitle: "langs.en.short", value: "en" },
	];

	return (
		<DropDown
			className={cx("header_lang-select")}
			options={langsOptions}
			onOptionClick={({ value }) => togglLocale(value)}
			activeOptionValue={currentLang}
		/>
	);
};
