import React from "react";
import classnames from "classnames";
import {
	LogoFullWhite,
	LogoFullColor,
	LogoShortWhite,
	LogoShortColor,
} from "../../assets/logo/logo";

import { Link } from "react-router-dom";

const Logo = ({ type = "fullWhite", className = "" }) => {
	const Logo = {
		fullWhite: {
			icon: LogoFullWhite,
			className: "logo-svg",
		},
		fullColor: {
			icon: LogoFullColor,
			className: "logo-svg",
		},
		shortWhite: {
			icon: LogoShortWhite,
			className: "logo-svg--short",
		},
		shortColor: {
			icon: LogoShortColor,
			className: "logo-svg--short",
		},
	}[type || "fullWhite"];

	return (
		<Link
			to='/'
			className={classnames(`logo-wrapper ${className}`, {
				"logo-wrapper--no-cursor": false,
			})}>
			<Logo.icon className={Logo.className} />
		</Link>
	);
};

export default Logo;
