import React, { useState } from "react";
import classnames from "classnames";
import { FormattedMessage, useIntl } from "react-intl";

import { Row, Col, SliderArrows, Button } from "../export";
import { PartnersLogo } from "../../assets/cliparts/homepage/partners-section/export";
import Avatar1 from "../../assets/images/avatar.jpg";
import Avatar2 from "../../assets/images/avatar2.jpg";

import classNames from "classnames";

const CasesSlider = ({
	logo = true,
	sliderArrows = true,
	className = "",
	handleSlideChange,
}) => {
	const slideClass = "cases-slide";

	const intl = useIntl();
	const casesKey = `homepage.casesSection.cases`;
	const slidesCount = Number(
		intl.formatMessage({
			id: `${casesKey}.itemsCount`,
		}),
	);

	const [activeSlide, setActiveSlide] = useState(
		sliderArrows ? slidesCount : 1,
	);

	const handleIconClick = (dir = 1, random) => {
		const newSlideNumber = activeSlide + dir;
		if (newSlideNumber > 0 && newSlideNumber <= slidesCount) {
			setActiveSlide(newSlideNumber);
			handleSlideChange && handleSlideChange(newSlideNumber);
		} else if (newSlideNumber < 0) {
			setActiveSlide(slidesCount);
			handleSlideChange && handleSlideChange(slidesCount);
		} else if (newSlideNumber > slidesCount) {
			setActiveSlide(1);
			handleSlideChange && handleSlideChange(1);
		} else if (random) {
			if (activeSlide === 1) {
				setActiveSlide(slidesCount);
				handleSlideChange && handleSlideChange(slidesCount);
			} else if (activeSlide === slidesCount) {
				setActiveSlide(1);
				handleSlideChange && handleSlideChange(1);
			}
		}
	};

	const caseIntl = `${casesKey}.${activeSlide}`;

	const title = intl.formatMessage({ id: `homepage.casesSection.title` });
	const feedback = intl.formatMessage({ id: `${caseIntl}.feedback` });
	const author = intl.formatMessage({ id: `${caseIntl}.author` });
	const authorPhoto = intl.formatMessage({ id: `${caseIntl}.authorPhoto` });
	const post = intl.formatMessage({ id: `${caseIntl}.post` });
	const companyLogo = intl.formatMessage({ id: `${caseIntl}.companyLogo` });

	// style={{
	// 	backgroundImage: `url(${companyLogo})`,
	// }}
	return (
		<div className={`cases-slider ${className}`}>
			<Col type='col-bw-start' className={`${slideClass}`}>
				{/* LOGO & ARROWS */}
				<Row type='row-bw-center' className={`${slideClass}-header`}>
					<div className={`${slideClass}-header_left`}>
						{companyLogo && (
							<img
								className={`${slideClass}-header_company-logo`}
								src={companyLogo}
							/>
						)}

						{!logo && <h4 className='h4 upper-case'>{title}</h4>}
					</div>
					{sliderArrows && slidesCount > 1 && (
						<SliderArrows black handleSlideChange={handleIconClick} />
					)}

					{/* REFETCH BTN */}
					{!sliderArrows && slidesCount > 1 && (
						<Button
							options={{ icon: "refresh", type: "secondary" }}
							config={{
								onClick: () => handleIconClick(1, true),
							}}
						/>
					)}
				</Row>
				{/* FEEDBACK TEXT */}
				<div style={{ width: "100%" }}>
					<blockquote className={`${slideClass}_feedback h4`}>
						{feedback}
					</blockquote>

					{/* SLIDER_BOTTOM */}
					<Row type='row-bw-start' className={`${slideClass}-bottom`}>
						{/* QUOTES & PHOTO */}

						<Row type='row-bw-start' className={`${slideClass}-bottom_left`}>
							<div className={`${slideClass}-quotes`} />
							<div
								className={`${slideClass}-photo`}
								style={{
									backgroundImage: `url(${authorPhoto})`,
								}}
							/>
						</Row>

						{/* AUTHOR DATA */}
						<div className={`${slideClass}-bottom_right`}>
							<p className={`${slideClass}_author`}>{author}</p>
							<p className={`${slideClass}_subtitle T-gray`}>{post}</p>
						</div>
					</Row>
				</div>
			</Col>
		</div>
	);
};

export default CasesSlider;
