import React from "react";
import classnames from "classnames";

const Tag = ({
	tag,
	type = "gray",
	size = "small",
	className = "",
	onClick,
	activeTag,
}) => {
	return (
		<button
			onClick={onClick && onClick}
			className={classnames(
				`tag tag--${size} bg-${type}`,
				{
					"tag-btn": onClick,
					"tag-btn--active": activeTag,
				},
				className,
			)}>
			{tag}
		</button>
	);
};

export default Tag;
