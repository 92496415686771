import React from "react";

export const CircleMask = ({ transform, className = "", children }) => {
	return (
		<div
			className={`circle-mask ${className}`}
			style={{
				transform,
			}}
		/>
	);
};

export default CircleMask;
