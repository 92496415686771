import React, { useEffect, useRef, useContext } from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';

import { Logo, Row } from '../export';

const Footer = ({}) => {
  const intl = useIntl();
  const topCols = ['productMenu', 'b2b', 'law'];
  const bottomCols = ['youtubeCertified', 'google', 'memorandum'];
  //  const { history } = useRouter();

  //©
  return (
    <footer className={'container footer'}>
      <Row type='row' className='border-bottom footer-top'>
        <div className='footer-col'>
          <Logo className='footer-logo' type={'fullColor'} />
        </div>
        {topCols.map((col, i) => {
          const intlKey = `footer.${col}`;

          const itemsCount = intl.formatMessage({
            id: `${intlKey}.itemsCount`,
          });

          return (
            <div className='footer-col' key={i}>
              {/* СOL TITLE */}
              <h6
                className='footer-menu_title T-gray'
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({ id: `${intlKey}.title` }),
                }}
              />
              {/* COL ITEMS */}
              <ul className='footer-menu_list'>
                {new Array(Number(itemsCount)).fill(0).map((feature, i) => {
                  const itemKey = `${intlKey}.items.${i + 1}`;
                  const title = intl.formatMessage({
                    id: `${itemKey}.title`,
                  });
                  const link = intl.formatMessage({
                    id: `${itemKey}.link`,
                    defaultMessage: '/',
                  });

                  const handleLinkClick = (link) => {
                    console.log(itemKey, itemKey === 'footer.productMenu');
                    window.location.replace(
                      itemKey.includes('productMenu')
                        ? `/protect${link}`
                        : link,
                    );
                  };

                  return (
                    <Link
                      onClick={() => handleLinkClick(link || '/')}
                      className='footer-menu_list-item link'
                      key={i}
                      dangerouslySetInnerHTML={{
                        __html: title,
                      }}
                    />
                  );
                })}
              </ul>
            </div>
          );
        })}
      </Row>

      <Row type='row' className='footer-bottom T-gray'>
        <div className='footer-col'>
          <FormattedMessage id='footer.copyright' /> <br />©
          {new Date().getFullYear()} <FormattedMessage id='footer.allRights' />
        </div>
        {bottomCols.map((item, i) => {
          return (
            <div className='footer-col' key={i}>
              <FormattedMessage id={`footer.${item}`} />
            </div>
          );
        })}
      </Row>
    </footer>
  );
};

export default Footer;
