import React from "react";
import { FormattedMessage } from "react-intl";
import { Loader, NavIcons, StatusIcons } from "../export";
import useRouter from "use-react-router";
import classnames from "classnames";

export const Button = ({
	options = {
		type: "primary",
		size: "middle",
		icon: false,
		iconDirection: "right",
	},
	config = {
		disabled: false,
		onClick: () => {},
	},
	text,
	className = "",
	link,
	loading,
}) => {
	const { history } = useRouter();

	const { type, size, Icon = "", iconDirection } = {
		type: {
			primary: "primary",
			secondary: "secondary",
			secondaryWhite: "secondary-white",
			secondaryOnDark: "secondary-on-dark",
			success: "success",
			noBorder: "no-border",
		}[options.type || "primary"],
		size: {
			small: "small",
			middle: "middle",
			big: "big",
			large: "large",
		}[options.size || "middle"],
		iconDirection: options.iconDirection,
		Icon: {
			arrow: NavIcons.Arrow,
			arrowBig: NavIcons.ArrowBig,
			refresh: NavIcons.Refresh,
			check: StatusIcons.Check,
		}[options?.icon],
	};
	// {
	// 	Svg:NavIcons.Refresh,
	// 	className: "icon-btn"
	// },

	// <NavIcons.ArrowBig direction='down' className='svg-btn--black' />

	const hadndleClick = () => {
		link ? history.push(link) : config.onClick && config.onClick();
	};

	return (
		<button
			className={classnames(
				`btn btn--${size} btn--${type} ${className}`,
				{
					"btn-icon": options.icon && !text,
				},
				`${Icon ? `btn-icon--${type} btn-icon--${size}` : ""}`,
			)}
			{...config}
			onClick={hadndleClick}>
			{Icon && <Icon direction={iconDirection} className={className} />}
			{!loading && text && <FormattedMessage id={text} defaultMessage={text} />}
			<Loader size={28} loading={loading} />
		</button>
	);
};

export default Button;
