export const required = (value) => {
	if (value === 0) {
		return false;
	} else if (!value || value === "") {
		return true;
	}
	return false;
};

export const isEmail = (value) => {
	if (!value || value === "") {
		return true;
	} else if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
		return false;
	}
	return true;
};
