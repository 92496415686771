import React, { useState, useRef, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import classnames from 'classnames';
import { useWindowSize } from 'react-use';
import useHover from '@react-hook/hover';
import { Link } from 'react-router-dom';
import useRouter from 'use-react-router';

import { Logo, NavIcons } from '../export';
import { HeaderTop, HeaderMenu, ContactButton, LangSelect } from './Header';

const MobileMenu = ({
  togglLocale,
  isMobileMenuOpen,
  setIsMobileMenuOpen,
  submenuItemsCount,
}) => {
  return (
    <div
      className={classnames('header-mobile', {
        'header-mobile--open': isMobileMenuOpen,
      })}>
      <div className='container row-bw-center header-top'>
        <Logo className='header-logo' type={'shortColor'} />

        <button
          onClick={() => setIsMobileMenuOpen(false)}
          className={classnames('header_burger-btn', {
            'header_burger-btn--open': true,
          })}>
          <NavIcons.Close size={29} viewBox={25} />
        </button>
      </div>

      <div
        className={classnames('header-mobile_content', {
          'header-mobile--open': true,
        })}>
        <div className='header-mobile_top'>
          {/* <LangSelect togglLocale={togglLocale} /> */}
          <HeaderMenu
            setIsMobileMenuOpen={setIsMobileMenuOpen}
            submenuItemsCount={submenuItemsCount}
          />
        </div>
        <ContactButton onClick={() => setIsMobileMenuOpen(false)} />
      </div>
    </div>
  );
};

export default MobileMenu;
