import React, { useEffect, useState } from "react";
import cx from "classnames";
import { motion, useMotionValue, useSpring } from "framer-motion";

export const CursorContext = React.createContext("cursorContext");

const SUPPORTED_CURSORS = [false, "top-cursor"];

const CustomCursor = ({ children }) => {
	const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
	const [cursurVisible, setCursorVisible] = useState(false);

	const cursorX = useMotionValue(-100);
	const cursorY = useMotionValue(-100);
	const springConfig = { damping: 25, stiffness: 700 };
	const cursorXSpring = useSpring(cursorX, springConfig);
	const cursorYSpring = useSpring(cursorY, springConfig);

	const [isPricePopupOpen, setIsPricePopupOpen] = useState(false);

	const onMouseMove = (event) => {
		const { pageX: x, pageY: y, clientY, clientX, ...rest } = event;
		const pathArr = event?.path || [];
		const hoverClass =
			pathArr[1]?.className ||
			pathArr[2]?.className ||
			pathArr[0]?.className ||
			"";
		const sliderClass = pathArr[0]?.className || "";

		const isHiddenCursor =
			typeof hoverClass === "string" &&
			(hoverClass?.includes("cursor") || hoverClass?.includes("homepage"));
		// &&
		// !hoverClass.includes("slider") &&
		// !sliderClass.includes("slider");
		// console.log("hoverClass ---", hoverClass); // event.path, "view", view
		if (isHiddenCursor && y < 900) {
			setCursorVisible(true);
		} else {
			setCursorVisible(false);
		}

		// SHOW POPUP on /pricing page when mouse in top right corner
		const windowInnerWidth = event.view.innerWidth;
		const windowInnerHeight = event.view.innerHeight;

		// console.log(
		// 	"y",
		// 	y,
		// 	"windowInnerHeight",
		// 	windowInnerHeight,
		// 	"x",
		// 	x,
		// 	"windowInnerWidth",
		// 	windowInnerWidth,
		// );
		if (x / windowInnerWidth > 0.85 && y / windowInnerHeight < 0.3) {
			setIsPricePopupOpen(true);
		}

		setMousePosition({ x, y });

		if (cursorX === x - 160) {
		}

		// cursorX.set(x - 160);
		// cursorY.set(y - 160);
	};

	useEffect(() => {
		document.addEventListener("mousemove", onMouseMove);

		return () => {
			document.removeEventListener("mousemove", onMouseMove);
		};
	});

	const onCursor = (cursorType) => {};

	return (
		<CursorContext.Provider
			value={{
				onCursor,
				mousePosition,
				isPricePopupOpen,
				setIsPricePopupOpen,
			}}>
			<motion.ins
				className={cx("movable", {
					"cursor--blue": cursurVisible,
					"cursor--hidden": !cursurVisible,
					// [`cursor-${cursor}`]: !!cursor,
				})}
				style={{
					// left: `${x}px`,
					// top: `${y}px`,
					transform: `translate3d(${mousePosition.x - 160}px, ${
						mousePosition.y - 160
					}px, 0)`,
					// translateX: cursorXSpring,
					// translateY: cursorYSpring,
				}}
			/>
			{children}
		</CursorContext.Provider>
	);
};

export default CustomCursor;
