export const Check = ({
	fill = "inherit",
	className = "",
	direction = "right",
}) => (
	<svg
		width='24'
		height='24'
		className={className}
		viewBox='0 0 24 24'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'>
		<path
			fill-rule='evenodd'
			clip-rule='evenodd'
			d='M21.4142 7L20.7071 7.70711L10.7071 17.7071L9.99999 18.4142L9.29288 17.7071L3.29289 11.7071L2.58579 11L4 9.58579L4.70711 10.2929L9.99999 15.5858L19.2929 6.29289L20 5.58579L21.4142 7Z'
			fill={fill}
		/>
	</svg>
);
