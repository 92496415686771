import React from "react";
import Lottie from "react-lottie";

import SmallBlueLoader from "../../assets/animations/small_loader_blue.json";
import SmallWhiteLoader from "../../assets/animations/small_loader_white.json";
import StandartBlueLoader from "../../assets/animations/standart_loader_blue";

export const Loader = ({
	loading,
	size = 80,
	loaderType = "smallWhite",
	children,
}) => {
	const animationData = {
		smallBlue: SmallBlueLoader,
		smallWhite: SmallWhiteLoader,
		standartBlue: StandartBlueLoader,
	}[loaderType];

	if (!loading) {
		return null;
	}

	return (
		<Lottie
			options={{
				loop: true,
				autoplay: true,
				animationData,
				rendererSettings: {
					preserveAspectRatio: "xMidYMid slice",
				},
			}}
			height={size}
			width={size}>
			{children}
		</Lottie>
	);
};
export default Loader;
