import React, { useEffect, useState, useContext } from "react";
import { useWindowSize } from "react-use";
import {  useIntl } from "react-intl";

import classnames from "classnames";
import { NavIcons } from "../export";
import PecentImg from "../../assets/cliparts/pricing/popup_percent.svg";
import { CursorContext } from "../CustomCursor/CustomCursor";

const Popup = () => {
	const { isPricePopupOpen, setIsPricePopupOpen } = useContext(CursorContext);
	const intl = useIntl();

	const phone = intl.formatMessage({ id: `contacts.phone` });

	// const [isOpen, setIsOpen] = useState({
	// 	isUserAttemptedToClose: false,
	// 	isPopupOpen: false,
	// });

	const { width } = useWindowSize();

	const handleUnload = (e) => {
		// if (!isOpen.isUserAttemptedToClose) {
		// setIsOpen({
		// 	isUserAttemptedToClose: true,
		// 	isPopupOpen: true,
		// });
		// if (e) {
		// 	e.returnValue = ""; // Legacy method for cross browser support
		// }
		// return "";
		// }
	};

	useEffect(() => {
		// window.addEventListener("beforeunload", handleUnload);
		// window.onbeforeunload = (event) => {
		// 	const e = event || window.event;
		// 	// Cancel the event
		// 	e.preventDefault();
		// 	return handleUnload; // Legacy method for cross browser support
		// };
	}, []);

	if (width <= 998) {
		return null;
	}

	return (
		<div
			className={classnames(`popup-overlay`, {
				"popup--open": isPricePopupOpen,
			})}>
			<div className='popup'>
				<button
					onClick={() => setIsPricePopupOpen(false)}
					className='popup_close-btn'>
					<NavIcons.Close fill='#151515' />
				</button>
				<div className='popup-top'>
					<img className='popup-top_img' src={PecentImg} alt='percent' />
					{/* popup_percent.svg */}
					<h4 className='popup-top_title h4'>Не устроила цена?</h4>
					<div className='popup-top_subtitle'>
						Звоните, мы предоставим дополнительную скидку
					</div>
				</div>

				{/* p120px */}

				<div className='popup-bottom'>
					<a href={`tel:${phone}`}>{phone}</a>
				</div>
			</div>
		</div>
	);
};

export default Popup;
