import React, { Suspense, useEffect, useState, useContext } from "react";
import { Router, Route, Switch } from "react-router";
import { createBrowserHistory } from "history";
import { AppRoutes } from "./routes/appRoutes";
import { LayoutApp } from "./compoents/export";
import { IntlProvider } from "react-intl";
import flatten from "flat";
import "./styles/main.scss";
import axios from "axios";
import { CustomCursor, CursorContext } from "./compoents/export";

export const AppHistory = createBrowserHistory();

function App() {
	const [intl_lang, setIntl] = useState(null);

	const get_locale = (locale_key) => {
		return (
			axios
				.get(`/get_locale_data?lang=${locale_key}`)
				// .get(
				// 	`https://contentscan.expanse.systems/get_locale_data?lang=${locale_key}`,
				// )
				.then((data) => {
					return data.data;
				})
		);
	};

	const togglLocale = (lang) => {
		get_locale(lang).then((data) => {
			setIntl({
				messages: flatten(data),
				locale: lang,
			});
			localStorage.setItem("lang", lang);
		});
	};

	useEffect(() => {
		togglLocale((intl_lang && intl_lang.locale) || "ru");
	}, []);

	if (!intl_lang) {
		return null;
	} else {
		return (
			<IntlProvider
				locale={intl_lang.locale}
				messages={intl_lang.messages}
				defaultLocale='ru'>
				<Suspense fallback={""}>
					<Router basename={"/"} history={AppHistory}>
						<CustomCursor>
							<div>
								<Switch>
									{AppRoutes.map((route, i) => (
										<Route
											key={i}
											path={route.path}
											exact={route.exact}
											component={(routeProps) => {
												const { Component, Layout = LayoutApp } = route;

												return (
													<LayoutApp togglLocale={togglLocale}>
														<Component {...routeProps} />
													</LayoutApp>
												);
											}}
										/>
									))}
								</Switch>
							</div>
						</CustomCursor>
					</Router>
				</Suspense>
			</IntlProvider>
		);
	}
}

export default App;
